.wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 2000;
  top: 0;
  left: 0;
}

.spinner {
  width: 3rem;
  height: 3rem;
  position: relative;
  top: 50%;
  left: 50%;
  margin: -1.5rem 0 0 -1.5rem;
}
