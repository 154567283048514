@import "styles/theme.scss";
@import "~@fortawesome/fontawesome-free/css/all.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* Non-responsive */

body {
  min-width: 970px;
}

/* Finesse the page header spacing */
.page-header {
  margin-bottom: 30px;
}
.page-header .lead {
  margin-bottom: 10px;
}


/* Non-responsive overrides
 *
 * Utilitze the following CSS to disable the responsive-ness of the container,
 * grid system, and navbar.
 */

/* Reset the container */
.container {
  width: 970px;
  max-width: none !important;
}

/* Demonstrate the grids */
.col-xs-4 {
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #eee;
  background-color: rgba(86,61,124,.15);
  border: 1px solid #ddd;
  border: 1px solid rgba(86,61,124,.2);
}

.container .navbar-header,
.container .navbar-collapse {
  margin-right: 0;
  margin-left: 0;
}

/* Always float the navbar header */
.navbar-header {
  float: left;
}

/* Undo the collapsing navbar */
.navbar-collapse {
  display: block !important;
  height: auto !important;
  padding-bottom: 0;
  overflow: visible !important;
}

.navbar-toggle {
  display: none;
}
.navbar-collapse {
  border-top: 0;
}

.navbar-brand {
  margin-left: -15px;
}

/* Always apply the floated nav */
.navbar-nav {
  float: left;
  margin: 0;
}
.navbar-nav > li {
  float: left;
}
.navbar-nav > li > a {
  padding: 15px;
}

/* Redeclare since we override the float above */
.navbar-nav.navbar-right {
  float: right;
}

/* Undo custom dropdowns */
.navbar .navbar-nav .open .dropdown-menu {
  position: absolute;
  float: left;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, .15);
  border-width: 0 1px 1px;
  border-radius: 0 0 4px 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
          box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
}
.navbar-default .navbar-nav .open .dropdown-menu > li > a {
  color: #333;
}
.navbar .navbar-nav .open .dropdown-menu > li > a:hover,
.navbar .navbar-nav .open .dropdown-menu > li > a:focus,
.navbar .navbar-nav .open .dropdown-menu > .active > a,
.navbar .navbar-nav .open .dropdown-menu > .active > a:hover,
.navbar .navbar-nav .open .dropdown-menu > .active > a:focus {
  color: #fff !important;
  background-color: #428bca !important;
}
.navbar .navbar-nav .open .dropdown-menu > .disabled > a,
.navbar .navbar-nav .open .dropdown-menu > .disabled > a:hover,
.navbar .navbar-nav .open .dropdown-menu > .disabled > a:focus {
  color: #999 !important;
  background-color: transparent !important;
}

/* Non-responsive */

table {
  &.table {
    th,
    td {
      color: #212529;
    }
    th {
      button {
        height: auto;
      }
    }
  }
  tr {
    th,
    td {
      td
      // max-width: 100px;
      // overflow: hidden;
      // text-overflow: ellipsis;
      // white-space: nowrap;
      &.col-6 {
        width: 50%;
      }
      &.col-3 {
        width: 25%;
      }
      &.actions {
        &.one {
          width: 32px;
        }
        &.two {
          width: 52px;
        }
        &.three {
          width: 70px;
          min-width: 66px;
        }
      }
    }
  }
}

.mb-3.required label:after {
  content: " *";
  color: red;
}

.modal {
  display: block;
}

.ReactModal__Overlay {
  z-index: 2000;
}

.ReactModal__Html--open,
.ReactModal__Body--open {
  // overflow: hidden;
  // padding-right: 15px;
}

.ReactModal__Content {
  overflow-y: auto;
  position: relative;
}

.btn.btn-link.btn-action {
  padding: 0;
  vertical-align: initial;
}

.container-fluid.content {
  padding: 19px 0 19px 0;
}

.card,
.nav-tabs {
  margin-bottom: 20px;
}

.react-crop-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile {
  position: relative;
  .destroy {
    position: absolute;
    right: 13px;
  }
}
.profile .rounded-circle {
  width: 20px;
  height: 20px;
  top: 10px;
  right: 10px;
}
.profile h1 {
  font-weight: normal;
  font-size: 20px;
  margin: 10px 0 0 0;
}
.profile h2 {
  font-size: 14px;
  font-weight: lighter;
  margin-top: 5px;
}
.profile .img-box {
  opacity: 1;
  display: block;
  position: relative;
}
.profile .img-box:after {
  content: "";
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.75);
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
}
.profile .img-box img {
  max-width: 100%;
}

.profile .img-box:after {
  -webkit-transition: all 0.5s ease-in-out 0s;
  -moz-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
}

.img-box:hover:after {
  opacity: 1;
}

.sticky-inner-wrapper {
  background: #f7f7f7;
}

.upload-image-preview {
  margin-bottom: 0.5rem;
}

.form-check-inline.form-check {
  flex-flow: wrap;
}

.react-datepicker-wrapper ~ .invalid-feedback,
textarea ~ .invalid-feedback {
  display: block;
}
.card-body .invalid-feedback {
  display: block;
}

a.open-modal {
  border-bottom: 1px dotted #007bff;
  text-decoration: none;
  cursor: pointer;
}

.thead-actions {
  float: right;
  .btn-sm {
    padding: 0;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.card-body .dropdown {
  float: right;
}

.modal-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.breadcrumb {
  padding: 0.75rem 1rem 0.75rem 1rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.float-right {
  float: right;
}

.ql-toolbar {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.ql-container {
  min-height: 200px;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

// .fc-more-popover {
//   max-height: 95%;
//   overflow-y: auto;
// }

.fc .fc-daygrid-body {
  z-index: 0 !important;
}

.fc-scrollgrid-sync-table tr {
  height: 100px;
}

.fc .fc-popover {
  z-index: 1 !important;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  cursor: pointer;
  border-radius: 5px;
  p {
    margin: 0;
  }
}

.input-field-select__option {
  min-height: 40px;
}

.profile.bg-light {
  // width: 400px;
  border: none;
  border-radius: 0.25rem;
  background-color: #f8f9fa;
  margin-bottom: 15px;
  height: 126px;
  img,
  svg {
    float: left;
  }
  a.open-modal {
    margin-top: 0.5rem;
    width: 0;
    overflow: visible;
    // text-decoration: underline;
  }
  a.open-modal,
  span {
    display: inline-block;
    margin-left: 0.5rem;
  }
  span > strong {
    display: inline-block;
    margin-top: 0.5rem;
  }
  &.success {
    background: #97c7a2 !important;
  }
  &.danger {
    background: #efc4c8 !important;
  }
}

.login {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;

  top: 50%;
  position: absolute;
  left: 50%;
  margin-top: -77px;
  margin-left: -165px;
}

[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.1s;
  visibility: visible;
}
[data-title]:after {
  content: attr(data-title);
  background-color: #fff;
  color: #111;
  font-size: 1rem;
  position: absolute;
  padding: 1px 5px 2px 5px;
  bottom: -1.4em;
  left: 10px;
  white-space: nowrap;
  box-shadow: 1px 1px 3px #222222;
  opacity: 0;
  // border: 1px solid #111111;
  z-index: 99999;
  visibility: hidden;
}
[data-title] {
  position: relative;
}

.show-additional {
  float: right;
}

.filter-checkboxes {
  padding-top: 38px;
}

// a.disabled {
//   pointer-events: none;
//   cursor: default;
//   opacity: 0.6;
// }

.disabled {
  .btn-primary, .btn-success:not(.non-blocked) {
    background: #d3d3d3;
    color: #fff;
    border-color: #d3d3d3;
    pointer-events: none;
  }
  .btn-link {
    pointer-events: none;
    i {
      color: #d3d3d3;
    }
  }
  .open-modal {
    pointer-events: none;
  }
  // input[type=checkbox] {
  //   pointer-events: none;
  // }
  // input, button {
  //   pointer-events: none;
  // }
}

.error > div.tox {
  border: 1px solid red;
}

span.dashed {
  color: #0d6efd;
  border-bottom: dashed 1px #0d6efd;
  cursor: pointer;
}

.selfemployed-avatar {
  background-color: #316bf4;
}